import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import Markdown from "marked-react"
import styled from "styled-components"

import TransitionWrapper from "components/common/Layout"
import Seo from "components/common/Seo"
import SubpageHeader from "components/common/SubpageHeader"

import Container from "../components/common/Container"
import Section from "../components/common/Section"

const query = graphql`
  query myQueryAndMyQuery {
    strapiPrivacyPolicy {
      page_title
      page_description
    }
  }
`

const StyledAboutTxtContent = styled.div`
  color: ${({ theme }) => theme.color.dark};
  font-size: 1.6rem;
  line-height: 2.4rem;
  text-align: justify;

  h1,
  h2,
  h3,
  h4 {
    padding: 2rem 0;
    font-size: 2rem;
    margin: 0;
  }

  p {
    padding-bottom: 15px;
  }
`

const PrivacyPolicy = ({ location }) => {
  const { strapiPrivacyPolicy } = useStaticQuery(query)
  const localMarkdown = text => <Markdown>{text}</Markdown>

  return (
    <TransitionWrapper location={location}>
      <Seo title="Privacy policy" />
      <SubpageHeader subpageTitle={strapiPrivacyPolicy.page_title} />
      <Section padding="6.5rem 0">
        <Container size="m">
          <StyledAboutTxtContent>{localMarkdown(strapiPrivacyPolicy.page_description)}</StyledAboutTxtContent>
        </Container>
      </Section>
    </TransitionWrapper>
  )
}

export default PrivacyPolicy
