import React from "react"

import PropTypes from "prop-types"
import styled from "styled-components"

const StyledHeader = styled.header`
  background: ${({ theme }) => theme.gradient.linear.purple_to_green};
  padding: 13.5rem 0 7.5rem 0;

  ${({ theme }) => theme.mq.s} {
    padding: 11rem 0 5.5rem 0;
  }

  ${({ theme }) => theme.mq.xs} {
    padding: 11rem 0 4.5rem 0;
  }
`

const StyledSectionTitle = styled.h1`
  font-size: 4.8rem;
  line-height: 4.8rem;
  color: ${({ theme }) => theme.color.text.inverted};
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;

  ${({ theme }) => theme.mq.s} {
    font-size: 3.2rem;
    line-height: 3.2rem;
  }

  ${({ theme }) => theme.mq.xs} {
    font-size: 2.6rem;
    line-height: 2.6rem;
  }
`

const SubpageHeader = ({ subpageTitle }) => (
  <StyledHeader>
    <StyledSectionTitle>{subpageTitle}</StyledSectionTitle>
  </StyledHeader>
)

SubpageHeader.propTypes = {
  subpageTitle: PropTypes.string.isRequired,
}

export default SubpageHeader
